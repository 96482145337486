import React, {useEffect, useState} from 'react';

import {TbKarate} from 'react-icons/tb'
import {animateScroll as scroll} from 'react-scroll';
import { 
    Nav, NavLogo, NavImg, MobileIcon, NavMenu, NavItem, NavLinks
} from './elements.js';
import navLogo from '../../../images/logo/Logo_weiss.png';

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        }else {
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavLogo to='/' href="#" onClick={toggleHome}>
                <NavImg src={navLogo} alt='Shotokan Esslingen' />
            </NavLogo>
            <MobileIcon onClick={toggle}>
                    <TbKarate />
                </MobileIcon> 
            <NavMenu>
                <NavItem>
                    <NavLinks scrollNav={scrollNav} to="/" href="#" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Home</NavLinks> 
                </NavItem>
                <NavItem>
                    <NavLinks scrollNav={scrollNav} to="athletes" href="#" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Athleten</NavLinks> 
                </NavItem>
                <NavItem>
                    <NavLinks scrollNav={scrollNav} to="training" href="#" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Training</NavLinks> 
                </NavItem>
                <NavItem>
                    <NavLinks scrollNav={scrollNav} to="coaches" href="#" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Trainerteam</NavLinks> 
                </NavItem>
                <NavItem>
                    <NavLinks scrollNav={scrollNav} to="contact" href="#" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Kontakt</NavLinks> 
                </NavItem>
            </NavMenu>
        </Nav>
    </>
  );
}

export default Navbar;