import React from 'react';
import { 
    SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu, SidebarElement
} from './elements.js';

const MobileNavbar = ({isOpen, toggle}) => {
    return (
       <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarElement>
                    <SidebarLink to="home" href="#" onClick={toggle}>
                        Home
                    </SidebarLink>
                </SidebarElement>
                <SidebarElement>
                    <SidebarLink to="athletes" href="#" onClick={toggle}>
                        Athleten
                    </SidebarLink>
                </SidebarElement>
                <SidebarElement>
                    <SidebarLink to="training" href="#" onClick={toggle}>
                        Training
                    </SidebarLink>
                </SidebarElement>
                <SidebarElement>
                    <SidebarLink to="coaches" href="#" onClick={toggle}>
                        Trainerteam
                    </SidebarLink>
                </SidebarElement>
                <SidebarElement>
                    <SidebarLink to="contact" href="#" onClick={toggle}>
                        Kontakt
                    </SidebarLink>
                </SidebarElement>
            </SidebarMenu>
        </SidebarWrapper>
       </SidebarContainer> 
    )
};

export default MobileNavbar;
