import styled from "styled-components";
import {FaTimes} from 'react-icons/fa';
import {Link} from 'react-scroll';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')}; 
    transition: 0.5s all ease;
`;

export const CloseIcon = styled(FaTimes)`
    color: var(--mainLightColor);
`;

export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`;

export const SidebarWrapper =styled.div`
color: var(--mainLightColor);
`;

export const SidebarMenu = styled.ul`
display: flex;
flex-direction: column;
gap: 15px;

@media screen and (max-width: 480px) {
    grid-template-rows:repeat(6, 60px);
}
`;

export const SidebarElement = styled.li`
list-style: none;
`;

export const SidebarLink = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.7rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
text-decoration: none;
color: var(--thirdLightColor);
cursor: pointer;

&:hover {
    color: var(--mainLightColor);
    transition: 0.2s ease-in-out;
}
`;