import React from 'react';
import { 
  InfoContainer, InfoWrapper, Column, Heading, Subtitle, InfoBtnLink
} from './elements';

const ImprintSection = () => {
return (
      <InfoContainer id='imprint'>
        <InfoWrapper>
          <Column>
            <Heading>Impressum</Heading>
            <Subtitle>
                        <br></br>
                        Impressum dieser Website erstellt über den Generator der Deutschen Anwaltshotline AG Angaben gem. § 5 TMG
                        <br></br>
                        Betreiber und Kontakt:
                        <br></br>
                        Dzemi Etemi
                        <br></br>
                        Krummenackerstr. 121/1
                        <br></br>
                        73733 Esslingen am Neckar
                        <br></br>
                        Telefonnummer: 0160 - 92510650
                        <br></br>
                        E-mail: info@karate-es.de
                        <br></br>
                        Web: www.karate-es.de
                        <br></br>
                        Verantwortlicher für die Webseite:
                        <br></br>
                        <br></br>
                        Laurent Etemi
                        <br></br>
                        Krummenackerstr. 121/1
                        <br></br>
                        73733 Esslingen am Neckar
                        <br></br>
                        E-mail: etemilaurent@outlook.de
                        <br></br>
                        Web: www.karate-es.de
                        <br></br>
                        Verantwortlicher für journalistisch-redaktionelle Inhalte gem. § 55 II RstV:
                        <br></br>
                        Dzemi Etemi
                        <br></br>
                        Recht auf Widerruf
                        <br></br>
                        Wenn Sie Shotokan Esslingen auffordern, Ihre personenbezogenen Daten nicht für die weitere Kontaktaufnahme zu verwenden und/oder zu löschen, so wird entsprechend verfahren. Daten, die für eine Auftragsabwicklung bzw. zu kaufmännischen Zwecken zwingend erforderlich sind, sind von einer Kündigung beziehungsweise von einer Löschung nicht berührt. Bitte haben Sie Verständnis dafür, dass im Falle des Widerrufs der personalisierte Service nicht mehr erbracht werden kann, da er auf der Verwendung der Kundendaten aufbaut.
                        <br></br>
                        Bider und Grafiken:
                        <br></br>
                        Links zu anderen Websites
                        <br></br>
                        Die Webseiten der Shotokan Esslingen enthalten gegebenenfalls Links zu anderen Websites. Der Shotokan Esslingen hat keinen Einfluss auf den redaktionellen Inhalt fremder Webseiten und darauf, dass deren Betreiber die Datenschutzbestimmungen einhalten.
                
            </Subtitle>
            <InfoBtnLink to="/"
              smooth={true} duration={300} spy={true} exact='true' offset={-80}>
              Zurück
            </InfoBtnLink>
          </Column>
        </InfoWrapper>
      </InfoContainer>
  )
}

export default ImprintSection;
