import React, {useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";

import firstAthlete from '../../../images/athletes/athlete1.JPG';
import secondAthlete from '../../../images/athletes/athlete2.JPG';
import thirdAthlete from '../../../images/athletes/athlete3.JPG';
import fourthAthlete from '../../../images/athletes/athlete4.JPG';
import fifthAthlete from '../../../images/athletes/athlete5.JPG';
import sixthAthlete from '../../../images/athletes/athlete6.JPG';
import seventhAthlete from '../../../images/athletes/athlete7.JPG';
import eightAthlete from '../../../images/athletes/athlete8.JPG';
import nineAthlete from '../../../images/athletes/athlete9.JPG';
import tenAthlete from '../../../images/athletes/athlete10.JPG';
import elevenAthlete from '../../../images/athletes/athlete11.JPG';
import twelfAthlete from '../../../images/athletes/athlete12.jpg';
import thirdteenAthlete from '../../../images/athletes/athlete13.JPG';

import AOS from 'aos';
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import {
    TeamContainer, Container, Heading, UnderHeading, 
    TeamCard, TeamCardInfo, TeamCardName, TeamCardList
} from './elements';

const TeamSection = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
    <>
        <TeamContainer id="athletes">
            <Container>
                <Heading data-aos="fade-up" >
                Athleten
                </Heading>
                <UnderHeading data-aos="fade-up" data-aos-delay="200">
                    Klicken Sie, um mehr zu erfahren.
                </UnderHeading>
            </Container >
                    <Swiper
                        data-aos="fade-up" 
                        data-aos-delay="400"
                        effect={"coverflow"}
                        speed={600}
                        loop={false}
                        grabCursor={true}
                        centeredSlides={true}
                        navigation={true}
                        // slidesPerView={1}
                        coverflowEffect={{
                          rotate: 30,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        modules={[Navigation, EffectCoverflow]}
                        className="mySwiper"
                        breakpoints={{
                        "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        "@0.75": {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        "@1.00": {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        "@1.50": {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                        }}
                        >

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + firstAthlete + ")"}}>
                            <TeamCardInfo>
                                <TeamCardName>
                                    Jeronim
                                </TeamCardName>
                                <TeamCardList>
                                    Deutscher Meister
                                    <br />  
                                    3. Platz Croatia Open
                                    <br />
                                    Zweifacher EM Teilnehmer
                                    <br />  
                                    Mehrfacher Landesmeister
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + secondAthlete + ")"}}>
                            <TeamCardInfo>
                                <TeamCardName>
                                    Laurent
                                </TeamCardName>
                                <TeamCardList>
                                    Dreifacher Deutscher Hochschulmeister
                                    <br /> 
                                    Mehrfacher Vize Deutscher Meister
                                    <br />  
                                    Mehrfacher Landesmeister
                                    <br />
                                    EM Teilnehmer
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + thirdAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Tamara
                                </TeamCardName>
                                <TeamCardList>
                                    Deutsche Meisterin
                                    <br />  
                                    7.Platz Youth League
                                    <br />  
                                    Mehrfache Landesmeisterin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + fourthAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Nives
                                </TeamCardName>
                                <TeamCardList>
                                    5. Karate Youth League
                                    <br />
                                    Vize Deutsche Meisterin
                                    <br />  
                                    Landesmeisterin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + fifthAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Valentin
                                </TeamCardName>
                                <TeamCardList>
                                    3. Platz Deutsche Meisterschaft
                                    <br />
                                    Landesmeister
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + sixthAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Eni 
                                </TeamCardName>
                                <TeamCardList>
                                    Dreifacher Deutscher Meister
                                    <br />  
                                    7.Platz Youth League
                                    <br />  
                                    Landesmeister
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + seventhAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Altina
                                </TeamCardName>
                                <TeamCardList>
                                    3. Platz Deutsche Meisterschaft
                                    <br />  
                                    Landesmeisterin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + eightAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Jenny
                                </TeamCardName>
                                <TeamCardList>
                                    Vize Landesmeisterin
                                    <br />
                                    5. Platz Deutsche Meisterschaft
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + nineAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Alvin
                                </TeamCardName>
                                <TeamCardList>
                                    Landesmeister
                                    <br />
                                    5. Platz Deutsche Meisterschaft
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + tenAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Anna
                                </TeamCardName>
                                <TeamCardList>
                                   3. Platz Deutsche Meisterschaft
                                    <br />  
                                    Landesmeisterin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + elevenAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Liza
                                </TeamCardName>
                                <TeamCardList>
                                    3. Platz Deutsche Meisterschaft
                                    <br />  
                                    Landesmeisterin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + twelfAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Fouaz
                                </TeamCardName>
                                <TeamCardList>
                                    5. Platz Deutsche Meisterschaft
                                    <br />  
                                    Landesmeister
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + thirdteenAthlete + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Dennis
                                </TeamCardName>
                                <TeamCardList>
                                   5. Platz Deutsche Meisterschaft
                                    <br />  
                                    Vize Landesmeister
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>
                    </Swiper>   
        </TeamContainer>
    </>
    )
}
export default TeamSection;