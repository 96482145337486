import styled from 'styled-components';
import headerBgImg from '../../../images/headers/header4.JPG';
import {Link} from 'react-scroll';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: var(--mainDarkColor);
    background-image: url(${headerBgImg}) ; 
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 89vh, 0 100%);

    @media screen and (max-width: 1204px){
        background-position: 35%;
    }
    @media screen and (max-width: 1004px){
        background-position: 25%;
    }
    @media screen and (max-width: 768px){
        background-position: 20%;
        background-attachment: scroll, local;
    }

:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.85) 100%);
    z-index: 2;
}
`;

export const HeroImg = styled.img`
width: 300px;  
height: auto; 
max-height: 230px;
@media screen and (max-width: 768px){
    width: 300px;
}
@media screen and (max-width: 480px){
    width: 300px;
}
`;

export const HeaderContent = styled.div`
z-index: 3;
max-width: 1200px;
padding: 8px 24px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const HeaderButtons = styled.div`
z-index: 3;
max-width: 1200px;
padding: 8px 24px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
`;

export const HeaderBtnWrapper = styled(Link)`
    position: relative;
    width: 140px;
    height: 55px;
    font-size: 1em;
    font-family: var(--bodyFont);
    font-weight: 400;
    margin: 2em 1.5em 0 0;
    padding: 1.1em 0 0 2.5em;
    background: transparent;
    border: transparent;
    color: var(--mainDarkColor);
    cursor: pointer;
    text-decoration: none;

     &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: skewX(-15deg);
        background-color: var(--mainLightColor);
        border: 2px solid var(--mainLightColor);
        z-index:-1;
    } 

    :hover::after {
       background: transparent;
       box-shadow: var(--mainLightColor) 0px 10px 20px -10px;
       transition: .5s;
    }

    :hover{
        color: var(--mainLightColor);
        transition: .5s;       
    }

    @media screen and (max-width: 768px){
    width: 120px;
    font-size: 0.8em;
    padding: 1.5em 0 0 2.8em;
    }
`;

export const HeaderBtnPlayWrapper = styled(Link)`
    position: relative;
    width: 140px;
    height: 55px;
    font-family: var(--bodyFont);
    font-weight: 400;
    margin: 2em 0 0 0;
    padding: 1.1em 0 0 2.2em;
    background: transparent;
    border: transparent;
    color: var(--thirdLightColor);
    font-size: 1em;
    cursor: pointer;
    text-decoration: none;

     &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: skewX(-15deg);
        border: 2px solid var(--thirdLightColor);
        z-index:-1;
    } 

    :hover::after {
        border-color: var(--mainLightColor);
        box-shadow: var(--mainLightColor) 0px 10px 20px -10px;
        transition: .5s;
    }

    :hover{
        color: var(--mainLightColor);
       transition: .5s;
    }

    @media screen and (max-width: 768px){
    width: 120px;
    font-size: 0.8em;
    padding: 1.5em 0 0 2.5em;
    }
`;