import React from 'react';
import './App.css';
import Home from './pages';
// import Gallery from './pages/gallery';
import AGB from './pages/agb';
import Imprint from './pages/imprint';
import DataProtection from './pages/dataprotection';


import {
  BrowserRouter as Router, Routes, Route
} from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} exact />
          {/* <Route path='/gallery' element={<Gallery />} exact /> */}
          <Route path='/imprint' element={<Imprint />} exact />
          <Route path='/dataprotection' element={<DataProtection />} exact />
          <Route path='/agb' element={<AGB />} exact />
        </Routes>
      </Router>
    </>
  );
}

export default App;
