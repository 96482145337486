import React, {useEffect} from 'react';
import { 
    ContactWrapper, ContactContainer, LeftContainer, RightContainer, Heading, Image, 
    ContactLink, ContactIcon, ContactInfo, ContactH2, ContactP, ContactBr, ContactPTitle, ExternIcon
} from './elements';
import { FaEnvelope, FaUserAlt, FaMobileAlt, FaWhatsapp, FaInstagram, FaGoogle, FaShare } from 'react-icons/fa'
import img from '../../../images/headers/header9.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
const ContactSection = () => {
    useEffect(() => {
        AOS.init();
      }, [])
return (
    <>
        <ContactWrapper id="contact">
            <Heading data-aos="fade-up" data-aos-delay="200">
                Kontakt
            </Heading>
            <ContactContainer>
                <LeftContainer data-aos="fade-up" data-aos-delay="400">
                    <ContactPTitle>
                       Unter den folgenden Kontaktdaten können Sie uns erreichen. 
                       <br />
                       Wir erwarten Sie in unserem Dojo!
                    </ContactPTitle>

        <ContactInfo>
            <ContactIcon>
                <FaUserAlt />
            </ContactIcon>
        <div>
            <ContactH2>Cheftrainer:</ContactH2>
            <ContactP>Dzemi Etemi</ContactP>
        </div>
        </ContactInfo>
        <ContactBr />
        <ContactInfo>
            <ContactIcon>
                <FaEnvelope />
            </ContactIcon>
        <div>
            <ContactH2>E-Mail:</ContactH2>
            <ContactLink href="mailto:info@karate-es.de ? Subject = Trainingsanfrage von [Name einfügen]">info@karate-es.de</ContactLink>
        </div>
        </ContactInfo>
        <ContactBr />
        <ContactInfo>
            <ContactIcon>
                <FaMobileAlt />
            </ContactIcon>
        <div>
            <ContactH2>Telefonnummer:</ContactH2>
            <ContactLink href="tel:+4917662054445">+49 176 62054445</ContactLink>
        </div>
        </ContactInfo>
        <ContactBr />
        <ContactInfo>
            <ContactIcon>
                <FaShare />
            </ContactIcon>
        <div>
            <ExternIcon href="https://wa.me/+4917662054445" aria-label='Schreib uns auf WhatsApp'>
                <FaWhatsapp />
            </ExternIcon>
            <ExternIcon href="https://www.instagram.com/shotokan_esslingen/" aria-label='Besuche uns auf Instagram'>
                <FaInstagram />
            </ExternIcon>
            <ExternIcon href="https://www.google.de/search?q=shotokan+esslingen&sxsrf=ALeKk00UJz6n2LeWXdfBm_Egt0npEvwcMg%3A1629024065805&source=hp&ei=Qe8YYZq2LeOPxc8Pv7mS6AQ&iflsig=AINFCbYAAAAAYRj9UdejFAC1nIntSZe77zXG3UDrQx_k&oq=shotokan+ess&gs_lcp=Cgdnd3Mtd2l6EAEYADIECCMQJzIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yAggmOgcIIxDqAhAnOg4ILhCABBCxAxDHARCjAjoFCC4QgAQ6BQgAEIAEOggIABCABBCxAzoRCC4QgAQQsQMQgwEQxwEQ0QM6DgguEIAEELEDEMcBENEDOgoILhDHARDRAxBDOgcIABCxAxBDOgQIABBDOgsILhCABBCxAxCDAToNCC4QsQMQxwEQ0QMQQzoECC4QQzoICC4QgAQQsQM6BwguELEDEEM6CwgAEIAEELEDEIMBOgoIABCABBCHAhAUOgcIABCABBAKOgsILhCABBDHARCvAToICAAQFhAKEB5Qiw9Y7iJglyxoAXAAeACAAXSIAZcIkgEEMTAuMpgBAKABAbABCg&sclient=gws-wiz" aria-label='Sieh dir unsere Facebook Seite an'>
                <FaGoogle />
            </ExternIcon>
            </div>
        </ContactInfo>
        </LeftContainer>
        <RightContainer data-aos="fade-up" data-aos-delay="400">
            <Image src={img} alt="Team Image" loading='lazy'/>
        </RightContainer>
            </ContactContainer>
        </ContactWrapper>

    </>
  )
}

export default ContactSection;