 import React from 'react';
import FooterSection from '../components/generals/footer';
import DataProtectionSection from '../components/dataprotection';

const AGB = () => { 

    return(
        
        <> 
            <DataProtectionSection />
            <FooterSection />
        </>
    )
};

export default AGB;