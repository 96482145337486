import React, {useEffect} from 'react';
import { 
  InfoContainer, InfoWrapper, Column1, Column2, Heading, Subtitle, InfoBtnLink, InfoImage, OfferP
} from './elements';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from '../../../images/headers/header7.png'

const AboutSection = () => {
  useEffect(() => {
    AOS.init();
  }, [])
return (
      <InfoContainer id='about'>
        <InfoWrapper>
          <Column1 data-aos="fade-up" data-aos-delay="200"> 
          <InfoImage
            src={img}
            alt=" "
            loading="lazy">
          </InfoImage>
            {/* <InfoVideo 
              src="https://www.youtube-nocookie.com/embed/CE4_J3DNe54" 
              title="Shotokan Esslingen Interview" 
              frameborder="0" 
              referrerPolicy='origin'
              allow="autoplay" 
              allowfullscreen
              loading="lazy"
              >
            </InfoVideo> */}
          </Column1>
          <Column2>
            <Heading data-aos="fade-up">Über Uns</Heading>
            <Subtitle data-aos="fade-up" data-aos-delay="200">
              Seit dem 1. April 2007 besteht unsere Karateschule Shotokan Esslingen. Im Stil "Shotokan" lehren wir Kata, Kumite und Selbstverteidigung. 
              Unsere sehr kompetenten Trainer verschiedenen Alters bringen viel Abwechslung, Vielfalt und ganz besonders viel Erfahrung vor allem im Bereich Kumite mit. 
              Bei uns im Team haben wir momentan mehrere Landesmeister, Deutsche Meister umd EM Teilnehmer. 
              Dadurch zählen wir im Bereich Kumite zu einem der erfolgreichsten Vereinen Deutschlands des deutschen Karateverbandes. 
              Zudem sind wir offizieller Regiostützpunkt vom Karateverband Baden-Württemberg.
              Verschaffen Sie sich einen ersten Einblick durch das Interview von ES-TV.
              Falls Sie mehr über uns in Erfahrung bringen wollen kommen Sie persönlich bei uns vorbei. 
            </Subtitle>
            <OfferP data-aos="fade-up" data-aos-delay="200">
              Ihnen steht ein kostenloser Probemonat zur Verfügung.
            </OfferP>
            <InfoBtnLink 
              data-aos="fade-up"
              href="#"
              to="contact"
              smooth={true} 
              duration={300} 
              spy={true} 
              exact='true' 
              offset={-80}>
              Kontakt
            </InfoBtnLink>
          </Column2>
        </InfoWrapper>
      </InfoContainer>
  )
}

export default AboutSection;
