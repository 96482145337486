import styled from "styled-components";
import {Link} from 'react-scroll';

export const InfoContainer = styled.div`
    padding: 80px 15px 80px 15px;
`;

export const InfoWrapper = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap-reverse;
justify-content: space-evenly;
align-items: center;
align-content: space-around;
row-gap: 30px;
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minimax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};   
}
`;

export const Column1 = styled.div`
`;

export const InfoVideo = styled.iframe`
width: 500px;
height: 280px;
    @media screen and (max-width: 500px){
        width: 375px;
        height: 210px; 
    } 
`;
export const InfoImage = styled.img`
width: 100%;
    height: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
`;

export const Column2 = styled.div`
display: flex;
flex-direction: column;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
align-content: space-between;
column-gap: 30px;
font-family: var(--bodyFont);
`;
export const Heading = styled.h1`   
margin-bottom: 20px;
color: var(--mainDarkColor);
    font-family: var(--bodyFont);
    font-size: 3em;

@media screen and (max-width: 480px){
 font-size: 32px;   
}
`;

export const Subtitle = styled.p`
text-align: justify;
max-width: 440px;
margin-bottom: 25px;
font-size: 1em;
font-weight: 400;
line-height: 24px;
color: var(--secondDarkColor);
font-family: var(--mainFont);
`;

export const OfferP = styled.p`
text-align: center;
margin-bottom: 25px;
font-size: 1em;
font-weight: 700;
color: var(--mainDarkColor);
font-family: var(--mainFont);
`;

export const InfoBtnLink = styled(Link)`
padding: 10px 22px;
color: var(--mainDarkColor);
border: 2.5px solid var(--mainDarkColor);
font-size: 16px;
outline: none;
cursor: pointer;
text-decoration: none;

&:hover {
    transition: all 0.3s ease-in-out;
    background: var(--mainDarkColor);
    color: var(--mainLightColor);
}
`;


