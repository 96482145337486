import styled from "styled-components";
import {Link} from 'react-router-dom';

export const InfoContainer = styled.div`
    padding: 70px 20px 20px 70px;
`;

export const InfoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;


export const Column = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: var(--bodyFont);
`;

export const Heading = styled.h1`
font-size: 2rem;
max-width: 440px;
margin-bottom: 20px;
line-height: 1.1;
color: var(--mainDarkColor);

@media screen and (max-width: 480px){
 font-size: 32px;   
}
`;

export const Subtitle = styled.p`
text-align: justify;
max-width: 1000px;
margin-bottom: 25px;
font-size: 1rem;
font-weight: 300;
line-height: 24px;
color: var(--secondDarkColor);
font-family: var(--mainFont);
`;

export const InfoBtnLink = styled(Link)`
padding: 10px 22px;
margin: 25px;
color: var(--mainDarkColor);
border: 2.5px solid var(--mainDarkColor);
font-size: 16px;
outline: none;
cursor: pointer;
text-decoration: none;

&:hover {
    transition: all 0.3s ease-in-out;
    background: var(--mainDarkColor);
    color: var(--mainLightColor);
}
`;


