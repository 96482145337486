import React from "react";
import logo from '../../../images/logo/Logo_weiss.png';
import logo2 from '../../../images/logo/LEWebsites.png';
import {animateScroll as scroll} from 'react-scroll';
import Container from 'react-bootstrap/Container';
import { 
    BsInstagram, BsWhatsapp, BsGoogle
} from 'react-icons/bs';
import { 
    Footer, Links, LegalLinks, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, SocialH3, SocialImg  
} from "./elements";

const FooterSection = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return(
        <Footer id="footer">
            <Container>
                <SocialLogo to="/" href="#" onClick={toggleHome}>
                    <SocialImg src={logo}  alt="LE Coding" loading="lazy" />
                </SocialLogo>
                <Links>
                    <SocialH3> Weitere Links:</SocialH3>
                    <LegalLinks to="/agb#agb" href="#">Datenschutz</LegalLinks> - <LegalLinks to="/imprint#imprint" href="#">Impressum</LegalLinks>
                </Links>
                <SocialH3> Erreichen Sie uns auf sozialen Medien:</SocialH3>
                <SocialIcons>
                        {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                        <BsFacebook />
                        </SocialIconLink> */}
                        <SocialIconLink href="https://www.instagram.com/shotokan_esslingen/" target="_blank" aria-label="Instagram">
                        <BsInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://wa.me/+4917662054445" target="_blank" aria-label="Whatsapp">
                        <BsWhatsapp />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.google.de/search?q=shotokan+esslingen&sxsrf=ALeKk00UJz6n2LeWXdfBm_Egt0npEvwcMg%3A1629024065805&source=hp&ei=Qe8YYZq2LeOPxc8Pv7mS6AQ&iflsig=AINFCbYAAAAAYRj9UdejFAC1nIntSZe77zXG3UDrQx_k&oq=shotokan+ess&gs_lcp=Cgdnd3Mtd2l6EAEYADIECCMQJzIGCAAQFhAeMgYIABAWEB4yBggAEBYQHjIGCAAQFhAeMgYIABAWEB4yAggmOgcIIxDqAhAnOg4ILhCABBCxAxDHARCjAjoFCC4QgAQ6BQgAEIAEOggIABCABBCxAzoRCC4QgAQQsQMQgwEQxwEQ0QM6DgguEIAEELEDEMcBENEDOgoILhDHARDRAxBDOgcIABCxAxBDOgQIABBDOgsILhCABBCxAxCDAToNCC4QsQMQxwEQ0QMQQzoECC4QQzoICC4QgAQQsQM6BwguELEDEEM6CwgAEIAEELEDEIMBOgoIABCABBCHAhAUOgcIABCABBAKOgsILhCABBDHARCvAToICAAQFhAKEB5Qiw9Y7iJglyxoAXAAeACAAXSIAZcIkgEEMTAuMpgBAKABAbABCg&sclient=gws-wiz" target="_blank" aria-label="Google">
                        <BsGoogle/>
                        </SocialIconLink>
                    </SocialIcons>
                    <SocialMediaWrap>
                    <WebsiteRights>Diese Webseite wurde erstellt von:</WebsiteRights>
                    <SocialLogo to="/" href="#" onClick={toggleHome}>
                    <SocialImg src={logo2} alt="LE Coding" loading="lazy"></SocialImg>
                    </SocialLogo>
                    <WebsiteRights>Shotkan Esslingen © {new Date().getFullYear()} All Rights reserved.</WebsiteRights>
                    </SocialMediaWrap>
            </Container>
        </Footer>
    )
}

export default FooterSection;