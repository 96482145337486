import React, {useState} from 'react';

import Navbar from '../components/generals/navbar';
import MobileNavbar from '../components/generals/mobileNavbar';
import HeaderSection from '../components/mainPage/header';
import AboutSection from '../components/mainPage/about';
import TrainingSection from '../components/mainPage/training';
import CoachSection from '../components/mainPage/coaches';
import TeamSection from '../components/mainPage/team';
import ContactSection from '../components/mainPage/contact';
import FooterSection from '../components/generals/footer';
// import Cookie from '../components/generals/cookies';

const Home = () => { 
    
    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return(
        
        <> 
            {/* <Cookie /> */}
            <MobileNavbar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeaderSection />
            <AboutSection />
            <TeamSection />
            <TrainingSection />
            <CoachSection />
            <ContactSection />
            <FooterSection />
        </>
    )
};

export default Home;