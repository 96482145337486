import React, {useEffect} from 'react';
import { 
    TrainingWrapper, TrainingContainer, LeftContainer, RightContainer, Heading, Image, 
    TrainingPTitle, TrainingBr, AdressLink,
    Table, TableRow, TableHeading, TableData, TableP, TableH3, TableH4
} from './elements';
import img from '../../../images/headers/header10.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TrainingSection = () => {
    useEffect(() => {
        AOS.init();
      }, [])
return (
    <>
        <TrainingWrapper id="training">
            <Heading data-aos="fade-up" >
                Trainingszeiten & -Orte
            </Heading>
            <TrainingContainer>
            <LeftContainer data-aos="fade-up" data-aos-delay="200">
            <Image src={img} alt="athlete kicking" loading="lazy"/>
        </LeftContainer>
                <RightContainer data-aos="fade-up" data-aos-delay="200">
                    <TrainingPTitle>
                        Das Training findet in der Eberhard-Bauer-Halle (Sportpark Weil) statt. 
                        <TrainingBr />
                        Die Adresse lautet:
                        <TrainingBr />
                        <AdressLink href='https://goo.gl/maps/Exds4FJPUt1T8btc8'>Weilstraße 199, 73733 Esslingen am Neckar.</AdressLink>
                        <TrainingBr />
                        Kostenlose Parkplätze befinden sich neben der Halle an der Bushaltestelle. 
                    </TrainingPTitle>
                    <Table>
                        <TableRow>
                            <TableHeading>
                                Montag
                            </TableHeading>
                            <TableHeading>
                                Mittwoch
                            </TableHeading>
                            <TableHeading>
                                Freitag
                            </TableHeading>
                        </TableRow>
                        <TableRow>
                            <TableData>
                                <TableH3>Kinder bis 6 Jahre</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>16:30 - 17:10</TableP>
                            </TableData>
                            <TableData>
                            </TableData>
                            <TableData>
                                <TableH3>Kinder bis 6 Jahre</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>16:30 - 17:10</TableP>
                            </TableData>
                        </TableRow>

                        <TableRow>
                            <TableData>
                                <TableH3>Kinder und Jugendliche</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>17:10 - 18:00</TableP>
                            </TableData>
                            <TableData>
                                <TableH3>Kinder und Jugendliche</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>17:10 - 18:00</TableP>
                            </TableData>
                            <TableData>
                                <TableH3>Kinder und Jugendliche</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>17:10 - 18:00</TableP>
                            </TableData>
                        </TableRow>

                        <TableRow>
                            <TableData>
                                <TableH3>Jugendliche und Erwachsene</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>18:00 - 19:00</TableP>
                            </TableData>
                            <TableData>
                                <TableH3>Jugendliche und Erwachsene</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>18:00 - 19:00</TableP>
                            </TableData>
                            <TableData>
                                <TableH3>Jugendliche und Erwachsene</TableH3>
                                <TableH4>Eberhard-Bauer-Halle</TableH4>
                                <TableP>18:00 - 19:00</TableP>
                            </TableData>
                        </TableRow>
                    </Table>
        </RightContainer>
            </TrainingContainer>
            <TrainingContainer>
                <LeftContainer>
                 </LeftContainer>
            </TrainingContainer>
        </TrainingWrapper>

    </>
  )
}

export default TrainingSection;