import styled from "styled-components";
import { ExternalLink } from 'react-external-link';

export const ContactWrapper = styled.div`
    padding: 80px 15px 80px 15px;
    text-align: center;
`;

export const ContactContainer = styled.div` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
    row-gap: 30px;
`;

export const Heading = styled.h1`
    color: var(--mainDarkColor);
    font-family: var(--bodyFont);
    font-size: 3em;
    padding-bottom: 50px;
`;

export const LeftContainer = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-around;
    row-gap: 30px;
    padding-bottom: 50px;
    background-color: var(--secondDarkColor);
    margin-bottom: 50px;
    padding: 25px;
    min-width: 300px;
`;

export const RightContainer = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
`;

export const ContactP = styled.p`
    color: var(--secondLightColor);
    font-size: 1.1em;
    padding-left: 2px;
    font-weight:300;
    text-decoration: none;
`;

export const ContactLink = styled(ExternalLink)`
    color: var(--secondLightColor);
    font-size: 1.1em;
    padding-left: 2px;
    text-decoration: none;

    :hover {
        color: var(--thirdLightColor);
        transition: 0.2s ease-in-out;
    }
`;

export const ContactPTitle = styled.p`
    color: var(--secondLightColor);
    font-size: 1.3em;
    padding-bottom: 45px;
`;

export const ContactIcon = styled.i`
    font-size: 24px;
    color: var(--mainLightColor);
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
`;

export const ContactInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
`;

export const ExternIcon = styled(ExternalLink)`
     font-size: 24px;
    color: var(--mainLightColor);
    transition: all 0.3s ease-in-out;
    margin-right: 20px;

    :hover {
        color: var(--thirdLightColor);
        transition: 0.2s ease-in-out;
    }
`;

export const ContactH2 = styled.h2`
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--mainLightColor);
`;

export const ContactBr = styled.hr`
    height: .5px;
    width: 100%;
    color: var(--thirdLightColor);
`;