import styled from 'styled-components';

export const TeamContainer = styled.div`
    background-color: var(--mainDarkColor);
    padding: 80px 15px 80px 15px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    gap: 25px;
`;

export const Heading = styled.h1`
    color: var(--mainLightColor);
    font-family: var(--bodyFont);
    font-size: 3em;
`;

export const UnderHeading = styled.h2`
    color: var(--mainLightColor);
    font-size: 1.2em;
    text-align: center;
    @media only screen and (max-width: 600px) {
        font-size: 1em;
    }
`;

export const TeamCardInfo = styled.div`
   position: absolute;
   bottom: 0;
   color: var(--mainLightColor);
   width: 100%;
   height: 100px;
   text-align: center;
   font-family: var(--bodyFont);
   opacity: 0;
   transition: .7s ease;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   z-index: 1;
`;

export const TeamCardName = styled.div`
   color: var(--mainLightColor);
   font-family: var(--bodyFont);
   z-index: 1;
`;

export const TeamCardList = styled.div`
   color: var(--thirdLightColor);
   font-family: var(--mainFont);
   z-index: 1;
`;

export const TeamCard = styled.div`
    position: relative;
    margin: 30px 0 0 0;
    width: auto;
    height: 600px;
    background: center / cover;
    overflow: hidden;

    ::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: center / cover;
        transition: .7s ease;
    }

    ::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.8871148801317402) 70%);
        opacity: 0;
        transition: .7s ease;
        z-index: 1;
    }

    :hover ${TeamCardInfo}{
        opacity: 1;
        background: scale(1.06);
    }

    :hover::before{
        opacity: 1;
    }

    :hover::after{
        transform: scale(1.06);
    }
`;