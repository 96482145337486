import React from 'react';
import headerLogo from '../../../images/logo/Logo_weiss.png'
import {
    HeaderContainer, HeaderContent, HeroImg, HeaderButtons, HeaderBtnWrapper, HeaderBtnPlayWrapper
} from './elements';

const HeaderSection = () => {
    return(
    <>
        <HeaderContainer id="home">
        <HeaderContent>
        <HeroImg src={headerLogo} alt="Logo Shotokan Esslingen" loading="lazy"></HeroImg>
            <HeaderButtons>
                <HeaderBtnWrapper to="contact" href="#">Kontakt</HeaderBtnWrapper>
                <HeaderBtnPlayWrapper to="about" href="#">Über Uns</HeaderBtnPlayWrapper>
            </HeaderButtons>
        </HeaderContent>
    </HeaderContainer> 
    </>
    )
}
export default HeaderSection;