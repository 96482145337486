import styled from "styled-components";
import img from '../../../images/headers/DSC_5781.JPG';
import { ExternalLink } from "react-external-link";
import {Link as LinkR} from 'react-router-dom';
import { Link as LinkS } from "react-scroll";

export const Footer = styled.section`
background: radial-gradient(circle, rgba(0,0,0,0.40252104259672616) 0%, rgba(0,0,0,0.7162465327928047) 50%), url(${img}) no-repeat fixed;
background-position: 0% 0%;
background-size: cover;
color: var(--mainLightColor);
font-size: 14px;
text-align: center;
padding: 50px ;
position: relative;

@media screen and (max-width: 1204px){
        background-position: 65%;
    }
    @media screen and (max-width: 1004px){
        background-position: 75%;
    }
    @media screen and (max-width: 768px){
        background-position: 80%;
        background-attachment: scroll, local;
    }
`;

export const Links = styled.div`
margin: 3vh 0 3vh 0;
`;

export const LegalLinks = styled(LinkR)`
    margin: 25px;
    color: var(--thirdLightColor);

    &:hover {
        color: var(--mainLightColor);
        transition: all 0.3s ease-in-out; 
        cursor: pointer;
    }
`;

export const SocialMediaWrap = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
max-width: 700px;
margin: 40px auto 0 auto;
`;

export const SocialLogo = styled(LinkS)`
height: auto;
cursor: pointer;
`;

export const WebsiteRights= styled.small`
margin-top: 20px;
font-size: .9em;
`;

export const SocialIcons = styled.div`
 text-align: center;
 display: flex;
justify-content: center;
align-items: center;
gap: 25px;
`;

export const SocialIconLink = styled(ExternalLink)`
color: var(--thirdLightColor);
font-size: 24px;

&:hover{
    color: var(--mainLightColor);
    cursor: pointer;
    transition: 0.3s ease-out;
}
`;

export const SocialH3 = styled.h3`
padding: 15px 0 15px 0;
`;

export const SocialImg = styled.img`
width: 220px;
`;