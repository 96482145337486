import styled from 'styled-components';
import '../../../App.css';
import {Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    row-gap: 15px;
    background: ${({scrollNav}) => (scrollNav ? 'linear-gradient(90deg, rgba(0,0,0,0.2344538157059699) 0%, rgba(0,0,0,0.761064459963673) 0%)' : 'transparent')};
    box-shadow: ${({scrollNav}) => (scrollNav ? 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px' : 'transparent')};
    position: sticky;
    height: 80px;
    margin-top: -80px;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
    @media screen and (max-width: 768px){
        justify-content: space-between;
        padding: 0 30px 0 30px;
    }
    `;

    export const NavLogo = styled.div`
    display: flex;
    justify-self: flex-start;
    cursor: pointer;
    list-style: none;
    `;

    export const NavImg = styled.img`
    width: 70px; 
    height: auto; 
    `;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -25px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
    export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        justify-self: flex-end;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
         color: ${({scrollNav}) => (scrollNav ? 'var(--mainDarkColor)' : 'var(--mainLightColor)')}; 
    }
    `;

    export const NavMenu = styled.ul`
    display: flex;
    row-gap: 40px;
    list-style: none;

    @media screen and (max-width: 768px) {
        display: none;
    }
    `;

    export const NavItem = styled.li`
        margin: 0 0 0 25px;
    `;

    export const NavLinks = styled(LinkS)`   
    font-family: var(--bodyFont);
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    color: var(--mainLightColor);
    text-decoration: none;

    ::after{
    content: "";
    display: block;
    transition: all 0.3s ease-in-out 0s;

    border-bottom: solid 1px var(--thirdLightColor);  
    transform: scaleX(0);  
    }

    &:hover::after {
        visibility: visible;
        transform: scaleX(1);
    }

    :hover::after {
        visibility: visible;
        transform: scaleX(1);
    }

     :hover {
        color: var(--thirdLightColor);
    } 

    &.active {
        transition: all 0.2s ease-in;
        color: var(--thirdLightColor);
    }

    &.active::after {
        visibility: visible;
        transform: scaleX(1);
    }
    `;

    export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px){
        display: none;
    }
    `;