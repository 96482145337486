import styled from "styled-components";
import { ExternalLink } from 'react-external-link';

export const TrainingWrapper = styled.div`
    padding: 80px 0 80px 0;
    text-align: center;
`;

export const TrainingContainer = styled.div` 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
    row-gap: 30px;
`;

export const Heading = styled.h1`
    color: var(--mainDarkColor);
    font-family: var(--bodyFont);
    font-size: 3em;
    padding-bottom: 50px;
`;

export const RightContainer = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    row-gap: 30px;
    padding-bottom: 50px;
    background-color: var(--secondDarkColor);
    margin-bottom: 50px;
    padding: 20px;
    min-width: 300px;
    @media screen and (max-width: 768px){
        
    }

`;

export const LeftContainer = styled.div` 
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
`;

export const TrainingP = styled.p`
    color: var(--secondLightColor);
    font-size: 15px;
    padding-left: 2px;
`;

export const TrainingPTitle = styled.p`
    color: var(--secondLightColor);
    font-size: 1.3em;
    padding-bottom: 15px;
`;

export const TrainingIcon = styled.i`
    font-size: 24px;
    color: var(--mainLightColor);
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
`;

export const TrainingInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
`;

export const TrainingH4 = styled.h2`
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--mainLightColor);
`;

export const TrainingBr = styled.br`
    height: 1.5px;
    width: 100%;
    color: var(--thirdLightColor);
`;

export const Table = styled.table`
color: var(--mainLightColor);
`;
export const TableRow = styled.tr`
`;

export const TableHeading = styled.th`
color: var(--mainDarkColor);
background-color: var(--secondLightColor);
padding: 5px;
border: 1px solid var(--secondLightColor);
`;
export const TableData = styled.td`
padding: 5px;
border: 1px solid var(--secondLightColor);
`;
export const TableP = styled.p`
font-size: 0.8em;
`;
export const TableH3 = styled.p`
font-size: 1em;
font-weight: 900;
`;
export const TableH4 = styled.p`
padding: 3px;
font-size: 0.9em;
font-weight: 200;
`;

export const AdressLink = styled(ExternalLink)`
font-style: none;
padding: 7px;
color: var(--secondLightColor);
`;