import React from 'react';
import FooterSection from '../components/generals/footer';
import DataProtectionSection from '../components/dataprotection';

const DataProtection = () => { 
    
    return(
        
        <> 
            <DataProtectionSection />
            <FooterSection />
        </>
    )
};

export default DataProtection;