import React, {useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";

import firstCoach from '../../../images/coaches/coachXh2.JPG';
import secondCoach from '../../../images/coaches/coachQ.JPG';
import thirdCoach from '../../../images/coaches/coachH.JPG';
import fourthCoach from '../../../images/coaches/coachE.JPG';
import fifthCoach from '../../../images/coaches/coachL.JPG';
import sixtCoach from '../../../images/coaches/coachJ.JPG';

import AOS from 'aos';
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import {
    TeamContainer, Container, Heading, UnderHeading, 
    TeamCard, TeamCardInfo, TeamCardName, TeamCardList
} from './elements';

const CoachSection = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    return(
    <>
        <TeamContainer id="coaches">
            <Container>
                <Heading data-aos="fade-up" data-aos-delay="200">
                Trainerteam
                </Heading>
                <UnderHeading data-aos="fade-up" data-aos-delay="400">
                    Klicken Sie, um mehr zu erfahren.
                </UnderHeading>
            </Container>
                    <Swiper
                       data-aos="fade-up" 
                       data-aos-delay="400"
                       effect={"coverflow"}
                       speed={600}
                       loop={false}
                       grabCursor={true}
                       centeredSlides={true}
                       navigation={true}
                       coverflowEffect={{
                         rotate: 30,
                         stretch: 0,
                         depth: 100,
                         modifier: 1,
                         slideShadows: true,
                       }}
                       modules={[Navigation, EffectCoverflow]}
                       className="mySwiper"
                       breakpoints={{
                       "@0.00": {
                           slidesPerView: 1,
                           spaceBetween: 10,
                       },
                       "@0.75": {
                           slidesPerView: 2,
                           spaceBetween: 20,
                       },
                       "@1.00": {
                           slidesPerView: 3,
                           spaceBetween: 40,
                       },
                       "@1.50": {
                           slidesPerView: 4,
                           spaceBetween: 50,
                       },
                       }}
                        >

<SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + firstCoach + ")"}}>
                            <TeamCardInfo>
                                <TeamCardName>
                                    Dzemi
                                </TeamCardName>
                                <TeamCardList>
                                    Gründer und Leiter des Vereins
                                    <br />  
                                    5. Dan - Schwarzgurt
                                    <br />
                                    Lizensierter B-Trainer
                                    <br />  
                                    Lizensierter Prüfer
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + secondCoach + ")"}}>
                            <TeamCardInfo>
                                <TeamCardName>
                                    Qassim
                                </TeamCardName>
                                <TeamCardList>
                                    5. Dan - Schwarzgurt
                                    <br /> 
                                    Langjähriger Kata Trainer
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + thirdCoach + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Hakan
                                </TeamCardName>
                                <TeamCardList>
                                    5. Dan - Schwarzgurt
                                    <br /> 
                                    Lizensierter B-Trainer
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + fourthCoach + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Eva
                                </TeamCardName>
                                <TeamCardList>
                                    1. Dan - Schwarzgurt
                                    <br />  
                                    Lizensierte C-Trainerin
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + sixtCoach + ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Jeronim
                                </TeamCardName>
                                <TeamCardList>
                                    1. Dan - Schwarzgurt
                                    <br />
                                    Sportökonom
                                    <br />
                                    Langjährige Trainererfahrung
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>

                        <SwiperSlide>
                        <TeamCard style={{backgroundImage: "url(" + fifthCoach+ ")"}}>
                        <TeamCardInfo>
                                <TeamCardName>
                                    Laurent
                                </TeamCardName>
                                <TeamCardList>
                                    2. Dan - Schwarzgurt
                                    <br />
                                    Langjährige Trainererfahrung
                                </TeamCardList>
                            </TeamCardInfo>
                        </TeamCard>
                        </SwiperSlide>
                    
                    </Swiper>  
        </TeamContainer>
    </>
    )
}
export default CoachSection;