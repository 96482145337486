import React from 'react';
import FooterSection from '../components/generals/footer';
import ImprintSection from '../components/imprint';

const Imprint = () => { 

    return(
        
        <> 
            <ImprintSection />
            <FooterSection />
        </>
    )
};

export default Imprint;